































































































































































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import axios from 'axios';
const excel = require('../libs/excel');
const _ = require('lodash');

export default Vue.extend({
  components: {
    DatePicker,
  },
  directives: {
    Ripple,
  },

  data() {
    const range = [moment().subtract(7, 'days').valueOf(), moment().valueOf()];
    return {
      dataTypes: [
        { txt: '小時資料', id: 'hour' },
        { txt: '分鐘資料', id: 'min' },
      ],
      form: {
        monitors: Array<any>(),
        monitorTypes: Array<any>(),
        dataType: 'hour',
        range,
      },
      display: false,
      columns: Array<any>(),
      rows: Array<any>(),
      currentPage: 1,
    };
  },
  computed: {
    ...mapState('monitorTypes', ['monitorTypes']),
    ...mapState('monitors', ['monitors']),
    ...mapGetters('monitorTypes', ['mtMap', 'activatedMonitorTypes']),
    ...mapGetters('monitors', ['mMap']),
    resultTitle(): string {
      return `總共${this.rows.length}筆`;
    },
    myMonitors(): Array<any> {
      return this.monitors.filter((m: any) => m._id === 'me');
    },
  },
  watch: {},
  async mounted() {
    await this.fetchMonitorTypes();
    await this.fetchMonitors();

    if (this.monitors.length !== 0) {
      this.form.monitors.push(this.myMonitors[0]._id);
    }

    this.form.monitorTypes.push('ORP');
    this.form.monitorTypes.push('RDO');
  },
  methods: {
    ...mapActions('monitorTypes', ['fetchMonitorTypes']),
    ...mapActions('monitors', ['fetchMonitors']),
    ...mapMutations(['setLoading']),
    async query() {
      this.setLoading({ loading: true });
      this.display = true;
      this.rows = [];
      this.columns = this.getColumns();
      const monitors = this.form.monitors.join(':');
      const monitorTypes = this.form.monitorTypes.join(':');
      const url = `/HistoryReport/${monitors}/${monitorTypes}/${this.form.dataType}/${this.form.range[0]}/${this.form.range[1]}`;

      const ret = await axios.get(url);
      this.setLoading({ loading: false });
      for (const row of ret.data.rows) {
        row.date = moment(row.date).format('lll');
      }

      this.rows = ret.data.rows;
    },
    cellDataTd(i: number) {
      return (_value: any, _key: any, item: any) =>
        item.cellData[i].cellClassName;
    },
    getMtDesc(mt: string) {
      const mtCase = this.mtMap.get(mt);
      return `${mtCase.desp}(${mtCase.unit})`;
    },
    getColumns() {
      const ret = [];
      ret.push({
        key: 'date',
        label: '時間',
        stickyColumn: true,
      });
      let i = 0;
      for (const mt of this.form.monitorTypes) {
        const mtCase = this.mtMap.get(mt);
        for (const m of this.form.monitors) {
          const mCase = this.mMap.get(m);
          ret.push({
            key: `cellData[${i}].v`,
            label: `${mCase.desc}`,
            tdClass: this.cellDataTd(i),
          });
          i++;
        }
      }

      return ret;
    },
    async downloadExcel() {
      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:9000/' : '/';
      const monitors = this.form.monitors.join(':');
      let reportUnit = 'Min';
      if (this.form.dataType === 'hour') reportUnit = 'Hour';

      const url = `${baseUrl}HistoryTrend/excel/${monitors}/${this.form.monitorTypes.join(
        ':',
      )}/${reportUnit}/all/${this.form.range[0]}/${this.form.range[1]}`;

      window.open(url);
    },
    exportExcel() {
      const title = this.columns.map(e => e.label);
      const key = this.columns.map(e => e.key);
      for (let entry of this.rows) {
        let e = entry as any;
        for (let k of key) {
          e[k] = _.get(entry, k);
        }
      }
      const monitorTypes = this.form.monitorTypes.join('');
      const params = {
        title,
        key,
        data: this.rows,
        autoWidth: true,
        filename: `${monitorTypes}歷史資料查詢`,
      };
      excel.export_array_to_excel(params);
    },
    setThisWeek() {
      const startOfTheWeek = moment().startOf('week').valueOf();
      this.form.range = [startOfTheWeek, moment().valueOf()];
    },
    setThisMonth() {
      const startOfThisMonth = moment().startOf('month').valueOf();
      this.form.range = [startOfThisMonth, moment().valueOf()];
    },
    setThisQ() {
      const startOfThisQ = moment().startOf('Q').valueOf();
      this.form.range = [startOfThisQ, moment().valueOf()];
    },
  },
});
